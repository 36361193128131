import React from 'react'
import Helmet from 'react-helmet'

import {
  Layout,
  BannerSmall,
  TextBox,
  BlogSlider,
  SoftwarePerformance,
  TopReferences,
  QuickFacts,
  BannerLinks
} from '../components'

import BannerImage from '../assets/performance.jpg'
import BeratungImage from '../assets/bannerlinks/verlaessliche.beratung.jpg'
import CalenderImage from '../assets/bannerlinks/calendar.jpg'
import Motor from '../assets/software.stack.performance.02.jpg'

const cards = [
  {
    tag: 'Design',
    headline: 'Luxus Design Trends',
    subheadline: 'Trendscouting von den Besten',
    backgroundImage: null,
    readingTime: null,
    link: '/blog/luxus-design-trends/'
  },
  {
    tag: 'SEO',
    headline: '95% Uplift mit SEO',
    subheadline: 'Reichweite der Webseite maximieren',
    backgroundImage: null,
    readingTime: null,
    link: '/blog/mittels-seo-zur-besten-platzierung'
  },
  {
    tag: 'Strategie',
    headline: 'Experten Screening',
    subheadline: 'für Usability',
    backgroundImage: null,
    readingTime: null,
    link: '/blog/experten-screening/'
  },
  {
    tag: 'E-Commerce',
    headline: 'Conversion Checkliste',
    subheadline: 'für maximales Wachstum',
    backgroundImage: null,
    readingTime: null,
    whiteColor: null,
    link: '/blog/conversion-checkliste/'
  }
]

const facts = [
  {
    count: 100,
    suffix: '%',
    text: 'Modernes User Interface'
  },
  {
    count: 100,
    suffix: '%',
    text: 'Responsives Design'
  },
  {
    count: 50,
    suffix: '%',
    text: 'Mobile User'
  }
]

const boxes = [
  {
    text: 'Jetzt Termin vereinbaren',
    image: CalenderImage,
    link: '/kontakt',
    linkText: 'Zum Kontakt'
  }
]

const Performance = () => (
  <Layout>
    <Helmet
      title="Digitale Strategie von holzweg GmbH® "
      meta={[
        {
          name: 'description',
          content:
            'Die holzweg GmbH® bietet Web-Screening ✓ Konzepte ✓ Potentialanalysen ✓ Use-Cases und Personas ✓ Usability-Tests ✓ SEO ✓und Online Marketing ✓ für Ihr optimales digitales Konzept.'
        },
        {
          name: 'keywords',
          content: 'digitale strategie, online konzept, usability'
        },
        {
          property: 'og:description',
          content:
            'Die holzweg GmbH® bietet Web-Screening ✓ Konzepte ✓ Potentialanalysen ✓ Use-Cases und Personas ✓ Usability-Tests ✓ SEO ✓und Online Marketing ✓ für Ihr optimales digitales Konzept.'
        },
        {
          property: 'og:image',
          content: 'https://www.holzweg.com/static/performance-48fe5446a0da345a44df5bcac57d3065.jpg'
        }
      ]}
    />
    <BannerSmall image={BannerImage} title="Performance Power" />
    <TextBox
      title={
        <span>
          Auf der Überholspur?
          <br />
          Wir schon.
        </span>
      }
      text="Dort wo die Luft dünn wird, bringen wir Sie voran: unsere hoch qualifizierten Mitarbeiter erarbeiten über Web-Screening, Potenzialanalysen, Use-Cases und Persona Interviews ein optimales Konzept, das die Konkurrenz schwach aussehen lässt. Steigern Sie Ihren digitalen Reifegrad mit einem VIP-Boxenstopp bei holzweg!"
    />
    {/* <BlogSlider cards={cards} singleRow={true} /> */}
    <SoftwarePerformance
      image={Motor}
      headline={
        <span>
          Technologische <br /> Leistungssteigerung
        </span>
      }
      text={
        <span>
          Wie ein starker Motor arbeitet der Software Stack bei holzweg. Nur
          Komponenten, die sich im Qualifying top platzieren, stehen in
          Pole-Position. Fahren Sie Ihre Ideallinie mit unserer Power-Unit:
        </span>
      }
    />
    {/* <TopReferences /> */}
    {/* <QuickFacts facts={facts} color="red" /> */}
    <TextBox
      title="Walk with us on the holzweg"
      text="Ob Sonnen-Spaziergang oder Power-Lauf, wer sich mit uns trifft, kann schon mal ins Schwitzen kommen. Lassen Sie sich zu Spitzenleistungen antreiben und vereinbaren Sie jetzt Ihren Termin mit unseren Experten!"
    />
    <BannerLinks boxes={boxes} />
  </Layout>
)

export default Performance
